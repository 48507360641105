










































import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import BranchTeaser from '../../../Component/Branch/BranchTeaser/BranchTeaser.vue';
import CurrentBranch from '../../../Component/Branch/CurrentBranch/CurrentBranch.vue';
import RequestEmployees from '../../../Component/Layout/MainMenu/Module/RequestEmployeesModule/RequestEmployeesModule.vue';
import {BranchAndGeoLocationService} from '../../../Service/BranchAndGeoLocationService';

export default {
    name: 'RequestEmployeeForm',
    components: {CurrentBranch, BranchTeaser, RequestEmployees},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        branch()
        {
            return BranchAndGeoLocationService.getCurrentBranch();
        }
    }
};
