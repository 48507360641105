var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "requestEmployeeForm" }, [
    _c("div", { staticClass: "requestEmployeeForm__grid" }, [
      _c(
        "div",
        { staticClass: "requestEmployeeForm__gridLeft" },
        [
          _c("RequestEmployees", {
            attrs: {
              branch: _vm.branch,
              context: _vm.context.pageContext,
              inline: "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "requestEmployeeForm__gridRight" },
        [
          _vm.branch !== null
            ? _c("CurrentBranch", {
                attrs: {
                  branch: _vm.branch,
                  "company-color": false,
                  "btn-link": _vm.branch.url,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button",
                      fn: function () {
                        return [_vm._t("button")]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }